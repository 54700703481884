
import styled from 'styled-components';

const CenterContainer = styled.div`
    margin: auto;
    width: 70%;
    padding: 10px;
    
    
    h1{
        text-align: center;
        color: white;
    }
    p{
        color:white;
    }
    
`
export default CenterContainer;